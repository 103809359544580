import { HashLink as NavLink } from 'react-router-hash-link';
import ProductTop5Data from "../data/ProductTop5Data.json"
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className="product-bg text-center green pt-5">
      <div className="container">
        <div className="row footer-bb font-dancing-script">
          <h4 className="col-md-4">TOP 5 PROIZVODA</h4>
          <h4 className="col-4 d-none d-md-block">KONTAKT</h4>
          <h4 className="col-4 d-none d-md-block">DRUŠTVENE MREŽE</h4>
        </div>
        <div className="row footer-bb py-4">


          <div className="col-md-4 align-content-center">
            <div className="row justify-content-center g-0">
              {ProductTop5Data.map((item) => (
                <div className="col align-content-center">
                  <NavLink smooth to={`/products#${item.image}`}><img src={`/images/product/${item.image}.png`} alt={item.name} className="img-fluid"/></NavLink>                
                </div>
              ))}
            </div>
          </div>

          <div className="footer-bb font-dancing-script d-block d-md-none my-4">
            <h4 >KONTAKT</h4>
          </div>
          <div className="col-md-4 align-content-center">
            <h4 className="font-dancing-script">Broj telefona :</h4>
            <h4 className=""><a className="text-decoration-none green" href="tel:+381646627845">+381 64 66 27 845</a></h4>
            <h4 className="font-dancing-script">e-mail :</h4>
            <h4 className=""><a className="text-decoration-none green" href="mailto:sanja.andrea.nenad@gmail.com">sanja.andrea.nenad@gmail.com</a></h4>
          </div>
          <div className="footer-bb font-dancing-script d-block d-md-none my-4">
            <h4 >DRUŠTVENE MREŽE</h4>
          </div>
          <div className="col-md-4 align-content-center">
            <div className="row">
              <h6 className="col-6 text-end">
                <i className="bi bi-instagram icon-contact"></i>
              </h6>
              <h6 className="col-6 text-start">
                <i className="bi bi-facebook icon-contact"></i>
              </h6>
            </div>

            <h4 className="font-dancing-script">Budi jača od svojih izgovora</h4>
          </div>
        </div>
        <div className="container text-center py-2">
      <Link to="/privacy-policy" className="me-4 text-decoration-none green">Politika privatnosti</Link>
      <Link to="/cookie-policy" className="ms-4 text-decoration-none green">Kolačići</Link>
    </div>
      </div>
    </section>
  )
}
export default Footer