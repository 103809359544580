import { HashLink as NavLink } from 'react-router-hash-link';
import Customers from '../components/Customers';
import AboutUsData from '../data/AboutUsData.json';
import ProductTop5Data from '../data/ProductTop5Data.json';


const Home = () => {

  return (
    <>
      <section className="background-image-section-1 my-2 my-md-5  pt-5">
        <div className="container fade-in-element">
          <div className="row">
            <div className="col-md-5 align-content-bottom p-4 p-md-0">
              <img src={`/images/sanja_krstic/sanja_krstic.png`} alt="Aloe Vera" className="img-fluid" />
            </div>
            <div className="col-md-7 align-content-center text-center text-md-start">
              <h1 className="text-white py-3 py-md-5 font-dancing-script">Dobro dosli, moje ime je Sanja Krstic.</h1>
              <h5 className="text-white mb-4">Kao zastupnik Forever Living Kompanije, nudim Vam konsultacije vezane za naše prozvode, preporuku za svaku vrstu proizvoda koji može doprineti rešavanju Vaših zdravstvenih problema i ponudu da se pridružite našem timu.</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5">
        <div className="row">
          {AboutUsData.map((item) => (
            <div className="col-md-4 mt-4 mt-md-0" key={item.id}>
              <h1 className="text-center font-dancing-script green pb-2">{item.title}</h1>
              <img src={`/images/about-us/${item.image}.webp`} alt="Aloe Vera" className="img-fluid pb-3" />
              <p className="text-center text-md-start">{item.text}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="py-5 product-bg">
        <div className="container">
          <div className="text-center header-product">
            <h1 className="green font-dancing-script-header">Proizvodi</h1>
          </div>
          {ProductTop5Data.map((item) => (
            <div className="row">
              <div className="col-md-5 text-center text-md-start">
                <img src={`/images/product/${item.image}.png`} alt={item.name} className="img-fluid pb-md-3" />
              </div>
              <div className="col-md-7 align-content-center text-center text-md-start">
                <h2 className="green pb-3">{item.name}</h2>
                <h4>{item.short_description}</h4>
                <p>{item.description}</p>
                <div className="text-center text-md-start mt-4">
                <NavLink className="text-decoration-none link-style-button" smooth to={`/products#${item.image}`}>Saznaj više</NavLink>
                </div>
              </div>
            </div>
          ))}
          <div className="text-center mt-4 d-none d-md-block">
            <h5><a href="/products" className="text-decoration-none link-style-button-product">POGLEDAJTE SVE PROIZVODE</a></h5>
          </div>
          <div className="text-center mt-5 d-block d-md-none">
            <p><a href="/products" className="text-decoration-none link-style-button-product">POGLEDAJTE SVE PROIZVODE</a></p>
          </div>
        </div>
      </section>

      <Customers />
      
    </>
  )
}
export default Home