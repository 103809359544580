import { Fade } from "react-reveal";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <>
      <section>
        <div className="contact-bg-img align-content-center text-center green">
          <div className="container contact-bg opacity-75 p-5">
            <h1 className="pb-4">Kontaktirajte nas</h1>
            <h5>Potrebna Vam je stučna pomoć pri odabiru odredjenog proizvoda ili želite da se pridružite našem timu ? Kontaktirajte nas kako bi smo Vam na najbolji način pomogli i odgovorili na sva vasa pitanja.</h5>
          </div>
        </div>
      </section>

      <section className="container my-5">
        <div className="align-content-center mb-5 mb-md-0">
          <Fade className="fadeInRight" delay={300}>
            <div className="row text-center text-md-start mb-md-5">
              <div className="col-md-4 text-center">
                <h2 className="mb-3">
                  <i className="bi bi-telephone-fill icon-contact-big"></i>
                </h2>
                <p><a className="text-decoration-none icon-contact" href="tel:+381646627845">+381 64 66 27 845</a></p>
              </div>
              <div className="col-md-4 text-center">
                <h2 className="mb-3 mt-4 mt-md-0">
                  <i className="bi bi-envelope-fill icon-contact-big"></i>
                </h2>
                <p><a className="text-decoration-none icon-contact" href="mailto:sanja.andrea.nenad@gmail.com">sanja.andrea.nenad@gmail.com</a></p>
              </div>
              <div className="col-md-4 text-center">
                <h2 className="mb-3 mt-4 mt-md-0">
                  <i className="bi bi-hand-thumbs-up-fill icon-contact-big"></i>
                </h2>
                <div className="row">
                  <h6 className="col-6 text-end">
                    <i className="bi bi-instagram icon-contact"></i>
                  </h6>
                  <h6 className="col-6 text-start">
                    <i className="bi bi-facebook icon-contact"></i>
                  </h6>
                </div>
              </div>
            </div>
          </Fade>
        </div>
          <div className="col-md-6 text-center mx-auto">
            <ContactForm />
          </div>
      </section>
    </>
  )
}
export default Contact;