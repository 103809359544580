const AboutMe = () => {
  return (
    <section>
      <div className="container py-5">
        <img src="/images/about-me/about-me.webp" alt="Aloe Vera" className="img-fluid" />
      </div>

      <div className="customer-bg">
        <div className="container py-5 ">
          <div className="text-center my-4">
            <h1 className="green font-dancing-script-header">Dobro dosli, moje ime je Sanja Krstic</h1>
          </div>
          <h4 className="green text-center d-none d-md-block">Po struci sam farmaceutski tehničar i saradnica Forever living product kompanije. Majka sam četvoro dece. Nakon poslednjeg porodjaja imala sam problem sa varenjem hrane. Smetale su mi sve namirnice, voće, povrće, gazirani napici, testo i iako sam donekle uspela da rešim svoj problem lekovima iz apoteke, nisam bila u potpunosti zadovoljna efektom pa sam istraživala koji bi bio najprirodniji način da rešim svoj problem. Pretraga me dovela do aloe vere gela , zaštitnog znaka firme forever. Počela sam da ga konzumiram i znatno doprinela rešavanju svojih zdravstvenih problema. Kako sam bila oduševljena učinkom, postala sam zainteresovana da saznam sve o njihovim proizvodima. Sada ove preparate koristi cela moja porodica i okruženje a ja sam postala saradnik kompanije i imam puno iskustava koja želim da podelim sa vama.</h4>
          <p className="green text-center d-block d-md-none">Po struci sam farmaceutski tehničar i saradnica Forever living product kompanije. Majka sam četvoro dece. Nakon poslednjeg porodjaja imala sam problem sa varenjem hrane. Smetale su mi sve namirnice, voće, povrće, gazirani napici, testo i iako sam donekle uspela da rešim svoj problem lekovima iz apoteke, nisam bila u potpunosti zadovoljna efektom pa sam istraživala koji bi bio najprirodniji način da rešim svoj problem. Pretraga me dovela do aloe vere gela , zaštitnog znaka firme forever. Počela sam da ga konzumiram i znatno doprinela rešavanju svojih zdravstvenih problema. Kako sam bila oduševljena učinkom, postala sam zainteresovana da saznam sve o njihovim proizvodima. Sada ove preparate koristi cela moja porodica i okruženje a ja sam postala saradnik kompanije i imam puno iskustava koja želim da podelim sa vama.</p>
        </div>
      </div>
    </section>
  )
}

export default AboutMe;