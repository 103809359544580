import ProductData from '../data/ProductData.json';

const Products = () => {
  return (
    <section className="products py-5">
      <div className="text-center">
        <h1 className="green font-dancing-script-header">Saznajte više o našim najprodavanijim proizvodima</h1>
      </div>
      
      <div className="container">        
        {ProductData.map((item) => (
          <div className="row my-md-5" id={item.id}>
            <div className="col-md-4 text-center text-md-start align-content-center">
              <img src={`/images/product/${item.id}.png`} alt={item.title} className="img-fluid pb-3" />
            </div>
            <div className="col-md-8 align-content-center text-center text-md-start pading-right-products">
              <h2 className="green pb-3">{item.name}</h2>
              <h4>Opis proizvoda</h4>
              <p>{item.description}</p>
              <h4>Sastav</h4>
              <p>{item.scructure}</p>
              <h4>Efekti proizvoda</h4>
              <p dangerouslySetInnerHTML={{ __html: item.product_effects }}></p>
              <h4>Način upotrebe</h4>
              <p>{item.way_of_use}</p>           
              <div className="text-center text-md-start mt-4">
                <a href={item.link} className="text-decoration-none link-style-button" target="_blank"  rel="noopener noreferrer">Kupi proizvod</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
export default Products