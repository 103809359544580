const Business_cooperation = () => {
  return (
    <section>
      <div className="business-cooperation align-content-center text-center green">
        <div className="container opacity-75 p-md-5">
          <div className="product-bg">
            <h2 className="pt-3 pb-4">PRIDRUŽI SE NAŠEM TIMU</h2>
            <h5>Da li želite da uz samo nekoliko sati nedeljno ostvarite dodatni prihod u vašem kućnom budzetu . Potrebno je samo da kroz nekoliko nedeljnih časova rada preporučite najbolje proizvode od aloe vere na tržištu novim korisnicima. U zavisnosti od vašeg zalaganja i truda možete jako brzo dostići visoke pozicije u našem timu a samim tim i veću zaradu.</h5>
            <h5 className="py-3">Prednost ovog posla je upravo u tome što ste vi sami svoj šef i sami  kontrolišete koliko cete da radite a samim tim i koliko cete da zaradite.</h5>
            <h5 className="pb-3">Ukoliko ste zaintersovani za saradnju kontaktirajte nas i dobicete odgovore na sva vasa pitanja i nedoumice.</h5>
          </div>
        </div>
        <div className="mt-5">
          <a href="/contact" className="text-decoration-none link-style-button-bus-cooperation">KONTAKTIRAJ TE NAS</a>
        </div>
      </div>
      <div className="container py-5">
        <img src="/images/business-cooperation/business-cooperation1.webp" alt="Aloe Vera" className="img-fluid pe-md-5 h-100" />
        <img src="/images/business-cooperation/business-cooperation2.webp" alt="Aloe Vera" className="img-fluid pt-4 pt-md-0 ps-md-5" />
      </div>
    </section>
  )
}
export default Business_cooperation;