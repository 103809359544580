import './App.css';
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Products from './pages/Products';
import Header from './components/Header';
import Footer from './components/Footer';
import AboutMe from './pages/About-me';
import Business_cooperation from './pages/Business-cooperation';
import Contact from './pages/Contact';
import BackToTopButton from './components/BackToTopButton';
import PrivacyPolicy from './pages/Privacy-policy';
import CookiesPolicy from './pages/Cookie-policy';

function App() {
  return (
    <>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<Products />} />      
      <Route path="/about-me" element={<AboutMe />} />
      <Route path="/business-cooperation" element={<Business_cooperation />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiesPolicy />} />
    </Routes>
    <Footer />
    <BackToTopButton />
    </>
    
  );
}

export default App;

