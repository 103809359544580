import Slider from "react-slick"
import CustomersData from "../data/CustomersData.json"

const Customers = () => {
  return (
    <section className="customer-bg pt-5">
      <div className="container">
        <div className="text-center header-product">
          <h1 className="green font-dancing-script-header">Iskustva naših korisnika</h1>
        </div>
        <Slider
          speed={2000}
          autoplaySpeed={5000}
          swipeToSlide={true}
          arrows={false}
          centerMode={false}
          focusOnSelect={true}
          autoplay={true}
          slidesToShow={3}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {CustomersData.map((item) => (
            <div className="" key={item.id}>
              <div className="row">
                <div className="p-5 text-center">
                  <div className="customer-image my-3">
                    <img src={`/images/customers/${item.name}.png`} alt={item.name} className="h-100 mx-auto" />
                  </div>
                  <h4 className="green mb-3">{item.name}</h4>
                  <p>{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
export default Customers