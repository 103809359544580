import { Fade } from "react-reveal"
import validateForm from "../validation";
import { useRef, useState } from "react";

const ContactForm = () => {

  const formRef = useRef();
  const [formData, setFormData] = useState({
    message: "",
    to: "sanja.andrea.nenad@gmail.com"
  });
  const [formSubmited, setFormSubmited] = useState(false);

  const sendContactMessage = (event) => {
    if (!validateForm(formRef.current)) return;
    const _formData = new FormData();
    for (var i in formData) {
      _formData.append(i, formData[i]);
    }
    fetch('https://contactform.orla.rs/mail_cv.php', { method: 'POST', body: _formData }).then(res => res.text());
    setFormSubmited(true);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Fade className="fadeInLeft" delay={300}>
        <div className="green shadow p-5 border-radius-20">
          {!formSubmited && (
            <form
              onSubmit={(e) => e.preventDefault()}
              action="#"
              name="contact-form"
              ref={formRef}
            >
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-input-fields"
                      defaultValue=""
                      placeholder="Ime"
                      required
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="phone"
                      name="phoneNumber"
                      className="form-input-fields"
                      defaultValue=""
                      placeholder="Telefon"
                      required
                      onChange={changeHandler}
                    />

                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="email"
                      id="emailid"
                      name="email"
                      className="form-input-fields"
                      defaultValue=""
                      placeholder="E-mail"
                      required
                      onChange={changeHandler}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-input-fields"
                      rows={5}
                      placeholder="Poruka"
                      required
                      defaultValue={""}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-12 text-center">
                  <button type="submit" className="btn-form-submit" onClick={sendContactMessage}>POŠALJI</button>
                </div>
              </div>
            </form>
          )}
          {formSubmited && (
            <h5 className="w-100 my-5 green" >Poruka je uspešno poslata</h5>
          )}
        </div>
      </Fade>
    </>
  )
}
export default ContactForm;